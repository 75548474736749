import { useState, useEffect } from "react";
import Dialog from "../../Components/Dialog";
import jsPDF from "jspdf";
import { baseurl } from "../../constants";

export default function PayouList() {
    const [isOpen, setIsOpen] = useState(false);
    const [payouts, setPayouts] = useState([]);
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState("");
    const [amount, setAmount] = useState("");
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize] = useState(10);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [total, setTotal] = useState(0);

    const openDialog = () => {
        setIsOpen(true);
        fetchProviders(); // Fetch providers when dialog opens
    };

    const closeDialog = () => {
        setIsOpen(false);
        resetForm(); // Reset form fields when closing
    };

    const resetForm = () => {
        setSelectedProvider("");
        setAmount("");
        setMessage("");
    };

    const fetchProviders = async () => {
        try {
            const response = await fetch(baseurl+"/user/get-all-providers", {
                method: "POST",
            });
            if (!response.ok) throw new Error("Failed to fetch providers");
            const data = await response.json();
            setProviders(data); // Set providers from the response
        } catch (error) {
            console.error('Error fetching providers:', error);
        }
    };

    const addPayout = async () => {
        const formData = new FormData();
        formData.append('ProviderId', selectedProvider);
        formData.append('Amount', amount);
        formData.append('Message', message);
        try {
            const response = await fetch(baseurl+"/payouts/add", {
                method: "POST",
                body: formData,
            });
            if (!response.ok) throw new Error("Failed to add payout");
            closeDialog(); // Close the dialog on success
            fetchPayouts(); // Refresh payouts list
        } catch (error) {
            console.error('Error adding payout:', error);
        }
    };

    const fetchPayouts = async () => {
        const formData = new FormData();
        formData.append('page', page);
        formData.append('pageSize', pageSize);
        if (fromDate) formData.append('FromDate', new Date(fromDate).toISOString());
        if (toDate) formData.append('ToDate', new Date(toDate).toISOString());

        try {
            const response = await fetch(baseurl+"payouts", {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const totalCountHeader = response.headers.get("X-Total-Count");
            setPayouts(data.payouts);
            setTotalCount(totalCountHeader);
            setTotalAmount(data.totalAmount);
            setTotal(data.total);
        } catch (error) {
            console.error('Error fetching payouts:', error);
        }
    };

    const handleApplyFilters = () => {
        fetchPayouts();
    };

    const handleResetFilters = () => {
        setFromDate("");
        setToDate("");
        setPage(1);
        fetchPayouts();
    };

    useEffect(() => {
        fetchPayouts();
    }, [page]);

    const printInvoice = (payout) => {
        const doc = new jsPDF();
        // Add company logo
        const logo = new Image();
        logo.src = "../images/logo.png"; // Update with your logo path
        logo.onload = () => {
            doc.addImage(logo, 'PNG', 10, 10, 50, 50); // Adjust positioning and size
            doc.setFontSize(20);
            doc.text("Tall3at", 70, 20);
            doc.setFontSize(12);
            doc.text(`Provider: ${payout.providerName}`, 10, 70);
            doc.text(`Phone: ${payout.providerPhone}`, 10, 80);
            doc.text(`Date: ${new Date(payout.date).toLocaleDateString()}`, 10, 90);
            doc.text(`Message: ${payout.message}`, 10, 100);
            doc.text(`Total Cost: ${payout.amount} ريال`, 10, 110);
            doc.save(`Invoice_${payout.id}.pdf`);
        };
    };

    const dialog = (
        <>
            <h2 className="text-xl font-semibold mb-4">إضافة تحويل</h2>
            <select
                value={selectedProvider}
                onChange={(e) => setSelectedProvider(e.target.value)}
                className="w-full px-3 border mt-3 h-[60px] text-[15px] rounded-md"
            >
                <option value="">اختر مزودًا</option>
                {providers.map(provider => (
                    <option key={provider.id} value={provider.id}>{provider.fullName}</option>
                ))}
            </select>
            
            <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder={"المبلغ"}
                className="w-full px-3 border mt-3 h-[60px] text-[15px] rounded-md"
            />
            <input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={"الرسالة"}
                className="w-full px-3 border mt-3 h-[60px] text-[15px] rounded-md"
            />
            <button onClick={addPayout} className="w-full text-[#ffffff] bg-[green] my-3 h-[60px] text-[15px] rounded-md">
                <span>تأكيد التحويل</span>
            </button>
        </>
    );

    return (
        <>
            <Dialog isOpen={isOpen} onClose={closeDialog} view={dialog} />
            <div className="bg-white shadow-sm rounded-lg p-6 max-w-full h-[100vh]">
                <div className="text-lg">
                    <i className="fas fa-wallet p-2"></i>
                    <i className="fas fa-arrow-left p-2"></i>
                    <span>المعاملات المالية</span>

                    <div>
                        <div className="flex justify-between">
                            <div>
                                <p className="text-grey my-3">الرصيد الإجمالي</p>
                                <p className="text-bold text-black text-[30px]">{total} ريال</p>
                            </div>
                        </div>

                        {/* Filter Controls */}
                        <div className="flex my-4">
                            <input 
                                type="date" 
                                value={fromDate} 
                                onChange={(e) => setFromDate(e.target.value)} 
                                className="px-3 border rounded-md mr-2" 
                            />
                            <input 
                                type="date" 
                                value={toDate} 
                                onChange={(e) => setToDate(e.target.value)} 
                                className="ms-3 px-3 border rounded-md" 
                            />
                            <button 
                                onClick={handleApplyFilters} 
                                className="ms-3 ml-2 px-3 text-[#ffffff] bg-[green] h-[60px] rounded"
                            >
                                <span>تمرير</span>
                            </button>
                            <button 
                                onClick={handleResetFilters} 
                                className="ml-2 px-3 text-[#ffffff] bg-red-500 h-[60px] rounded"
                            >
                                <span>إعادة تعيين</span>
                            </button>
                        </div>

                        <div className="overflow-x-auto my-3">
                            <table className="mt-[50px] text-right min-w-full bg-white border text-right border-gray-300 rounded-lg shadow-lg">
                                <thead>
                                    <tr className="bg-gray-200 text-gray-600">
                                        <th className="py-3 px-4">الرقم</th>
                                        <th className="py-3 px-4">المزود</th>
                                        <th className="py-3 px-4">الهاتف</th>
                                        <th className="py-3 px-4">المعاملة</th>
                                        <th className="py-3 px-4">التاريخ</th>
                                        <th className="py-3 px-4">المبلغ</th>
                                        <th className="py-3 px-4">الحالة</th>
                                        <th className="py-3 px-4">طباعة الفاتورة</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payouts.map(payout => (
                                        <tr key={payout.id} className="hover:bg-gray-100">
                                            <td className="py-2 px-4 border-b">{payout.id}</td>
                                            <td className="py-2 px-4 border-b">{payout.providerName}</td>
                                            <td className="py-2 px-4 border-b">{payout.providerPhone}</td>
                                            <td className="py-2 px-4 border-b">{payout.message}</td>
                                            <td className="py-2 px-4 border-b">{new Date(payout.date).toLocaleDateString()}</td>
                                            <td className="py-2 px-4 border-b text-green-500">{payout.amount}</td>
                                            <td className="py-2 px-4 border-b text-green-500">{payout.status}</td>
                                            <td className="py-2 px-4 border-b">
                                                <button 
                                                    onClick={() => printInvoice(payout)} 
                                                    className="text-white bg-blue-500 px-3 py-1 rounded"
                                                >
                                                    طباعة الفاتورة
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination Controls */}
                        <div className="flex justify-between mt-4">
                            <button 
                                onClick={() => setPage(prev => Math.max(prev - 1, 1))} 
                                disabled={page === 1}
                                className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                            >
                                Previous
                            </button>
                            <span>Page {page} of {Math.ceil(totalCount / pageSize)}</span>
                            <button 
                                onClick={() => setPage(prev => prev + 1)} 
                                disabled={page * pageSize >= totalCount}
                                className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}