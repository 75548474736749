
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseurl } from "../../constants";

export default function({id}){
  const [data, setData] = useState()
  const getData = async () => {
    const formdata = new FormData()
    formdata.append("id", id)
    try {
      const response = await fetch(baseurl + "provider/get-services", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: formdata
      });
      const json = await response.json();
      setData(json)
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!data) {
      getData()
    }
  }, []);

return <div>
  <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div className="breadcrumb-title pe-3">الرحلات</div>
  </div>
  <hr />

  <div className="card">
    <div className="card-body">
      <div className="table-responsive">
        <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap5">
          <div className="row">
            <div className="col-sm-12">

                <table id="DataTable" className="table table-striped table-bordered dataTable" role="grid" aria-describedby="example2_info">
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">م</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">الاسم</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">المدينة</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">سعر الحجز</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">تاريخ الاضافة</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">صورة</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">تعديل</span></th>
                      </tr>
                    </thead>
                    <tbody>

                      {data && data.map((item, index) => <tr role="row" className="odd">
                        <td><span className="mx-3">{index + 1}</span></td>
                        <td>{item.name}</td>
                        <td>{item.city}</td>

                        <td>{item.price}</td>
                        <td>{item.startTime}</td>
                        <td> <div className="d-flex align-items-center gap-3 cursor-pointer">
                          <img src={baseurl + "/uploads/" + item.image} className="rounded-full w-[44px] h-[44px]"  alt="" />
                        </div></td>
                        <td>

                          <Link state={{
                            row: item,
                          }} to={"/admin/package/edit"}>

                            <Link state={{
                              row: item,
                            }} to={"/admin/Product/detail"}>
                              <a type="button" asp-action="Details" className="btn btn-primary m-1 text-center"><i className="far fa-eye" /></a>
                            </Link>
                          </Link>

                        </td>
                      </tr>)}

                    </tbody>
                  </table>
              <br />
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
}