import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, formateDate } from "../../constants";
import axios from 'axios';

const Providers = () => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loader, showLoader, hideLoader] = useLoader();
  const [balanceFilter, setBalanceFilter] = useState(""); // State for balance filter
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  useEffect(() => {
    const fetchData = async () => {
      const formdata = new FormData();
      formdata.append("pageNumber", pageNumber);
      formdata.append("pageSize", pageSize);
      if (balanceFilter) {
        formdata.append("balanceFilter", balanceFilter); // Add the balance filter
      }
      if (searchTerm) {
        formdata.append("searchTerm", searchTerm); // Add the search term
      }
      const response = await axios.post(baseurl + 'user/get-providers', formdata);
      setData(response.data.items);
      setTotalCount(response.data.totalCount);
    };

    fetchData();
  }, [pageNumber, pageSize, balanceFilter, searchTerm]); // Add searchTerm as a dependency

  const totalPages = Math.ceil(totalCount / pageSize);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("هل أنت متأكد أنك تريد حذف هذا المزود؟");
    if (confirmDelete) {
      deleteProvider(id);
    }
  };

  const deleteProvider = async (providerId) => {
    try {
      const response = await axios.delete(`${baseurl}provider/${providerId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.status === 200) {
        console.log('Provider deleted successfully');
      } else {
        console.error('Failed to delete provider:', response.data);
      }
    } catch (error) {
      console.error('Error deleting provider:', error);
    }
  };

  const handleWalletClick = async (id) => {
    const confirmPay = window.confirm("هل تم سداد الرصيد لهذا المزود؟");
    if (confirmPay) {
      await providerPay(id);
    }
  };

  const providerPay = async (providerId) => {
    try {
      const formData = new FormData();
      formData.append('id', providerId);

      const response = await axios.post(`${baseurl}provider-pay`, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.status === 200) {
        console.log('تمت العملية بنجاح');
      } else {
        console.error('Failed to reset provider balance:', response.data);
      }
    } catch (error) {
      console.error('Error resetting provider balance:', error);
    }
  };

  return (
    <div>
      {loader}

      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">مزودي الخدمة</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">مزودي الخدمة</li>
            </ol>
          </nav>
        </div>
      </div>
      <hr />
      <div className="card">
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="searchTerm" className="form-label">بحث بالاسم أو الهاتف</label>
            <input
              type="text"
              id="searchTerm"
              className="form-control"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
              placeholder="أدخل الاسم أو رقم الهاتف"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="balanceFilter" className="form-label">فلتر الرصيد</label>
            <select
              id="balanceFilter"
              className="form-select"
              value={balanceFilter}
              onChange={(e) => setBalanceFilter(e.target.value)} // Update filter state
            >
              <option value="">الكل</option>
              <option value="positive">إيجابي</option>
              <option value="negative">سلبي</option>
              <option value="zero">صفر</option>
            </select>
          </div>
          <div className="table-responsive">
            <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap5">
              <div className="row">
                <div className="col-sm-12">
                  <table id="DataTable" className="table table-striped table-bordered dataTable" role="grid" aria-describedby="example2_info">
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending">م</th>
                        <th className="sorting_asc">الإسم</th>
                        <th className="sorting_asc">الإيميل</th>
                        <th className="sorting_asc">رقم الهاتف</th>
                        <th className="sorting_asc">تاريخ الانضمام</th>
                        <th className="sorting_asc">الرصيد</th>
                        <th className="sorting_asc">صورة</th>
                        <th className="sorting">تعديل</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.map((item, index) => (
                        <tr role="row" key={item.id}>
                          <td><span className="mx-3">{index + 1}</span></td>
                          <td>{item.fullName}</td>
                          <td>{item.email}</td>
                          <td>{item.userName}</td>
                          <td>{formateDate(item.createdAt)}</td>
                          <td>
                            <div className={`py-2 px-4  ${parseFloat(item.balance) > 0 ? 'text-green-500' : parseFloat(item.balance) < 0 ? 'text-red-500' : 'text-black'}`}>{item.balance}</div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center gap-3 cursor-pointer">
                              <img src={baseurl + "/uploads/" + item.profileImage} className="rounded-full w-[44px] h-[44px]" alt="" />
                            </div>
                          </td>
                          <td>
                            <button type="button" className="btn btn-dark mx-1" onClick={() => handleWalletClick(item.id)}>
                              <i className="fas fa-money-check-alt"></i>
                            </button>

                            <Link state={{ row: item }} to={"/admin/provider/detail"}>
                              <button type="button" className="btn btn-primary m-1 text-center">
                                <i className="far fa-eye" />
                              </button>
                            </Link>

                            <Link state={{ row: item }} to={"/admin/provider/edit"}>
                              <button type="button" className="btn btn-success mx-1">
                                <i className="fas fa-edit "></i>
                              </button>
                            </Link>

                            <button type="button" className="btn btn-danger mx-1" onClick={() => handleDelete(item.id)}>
                              <i className="far fa-trash-alt "></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />

                  <nav>
                    <ul className="pagination justify-content-center">
                      <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}>
                          السابق
                        </button>
                      </li>
                      {[...Array(totalPages)].map((_, index) => (
                        <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => setPageNumber(index + 1)}>
                            {index + 1}
                          </button>
                        </li>
                      ))}
                      <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setPageNumber(prev => Math.min(prev + 1, totalPages))}>
                          التالي
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Providers;