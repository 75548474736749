import { toast } from "react-hot-toast";

export const baseurl = "https://api.tall3at.com/"
//export const baseurl = "https://localhost:7023/"
export const formateDate = function formatDateTimeToAMPM(dateString) {
  const date = new Date(dateString); // Parse the date string

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString(undefined, options);

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12; // Convert to 12-hour format
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

  return `${formattedDate} at ${formattedTime}`;
}
export const font = "/Users/ahmad/Downloads/Amiri/Amiri-Regular.ttf"
export const onInputInvalid = (e) =>
e.target.setCustomValidity("هذا الحقل مطلوب")
export const OnInput = (e) => e.target.setCustomValidity("")
export const uploadImage=async (file)=>{
    const formdata = new FormData();
    formdata.append("image", file);
      const response = await fetch(baseurl+ "image/upload", {
        method: "POST",
        headers: {
            "Authorization":"Bearer "+localStorage.getItem("token")
          },
        body: formdata,
      });
      const json = await response.json();
      return json.image;
}

export const successNotify = () => toast.success('تمت العملية بنجاح');
export const errorNotify = () => toast.error('هناك خطأ في البيانات الرجاء التأكد وإعادة المحاولة');

